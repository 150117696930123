import React, {useContext, useState} from 'react';
import {ElementContext} from './ElementContext';
import countries from 'i18n-iso-countries';
import countryCodes from 'country-codes-list'
import { DateTime } from 'luxon'
import { useEffect } from 'react';
import UpdateUserPlan from './UpdateUserPlan';
import CheckingLoader from './CheckingLoader';
import UpdatePlanScreen from './UpdatePlanScreen';
import PreValidationDisclaimer from './PreValidationDisclaimer';
import { useTranslation } from 'react-i18next';
import { getGlobalUserInformation } from '../helpers/globalSettings';
import { useAuth } from '@frontegg/react';
import { getPhoneNumberInformation, getWhatsappApiTemplates } from '../helpers/whatsapp-api';
import {fetchBackend} from '../helpers/fetch'
import NotificationText from './NotificationText';


const CheckValidations = () => {

  const { setUserChecked, userPlan, setUserConfiguration, setDelayMsg, setOscillationMsg, subscriptionPaid, showAllPlans, setIsDarkMode, totalMsgs, totalSMS, disclaimerAccepted, showDisclaimer, setShowDisclaimer, setGlobalEnv, globalEnv, setUserPlan, setSubscriptionPaid, setSubscriptionId, setDisclaimerAccepted, setUserStripeData, setTenantId, setListNames, setMessagesToShow, setTotalMsgs, setTotalSMS, setAllTenantContacts, setTemplatesToShow, setScheduleExecutedToShow, setScheduleToShow, setUserInformation, setWhatsappTemplates, setUserWAPICredentials, setwAPIUserInformation, setSelectedWACredentials, setApprovedTemplates, setCloudConnectionsList, setAiTemplatesGenerated, setAiTemplatesGeneratedCount, setExtractionSettings, setIntegrationsUserInformation, setExecutedExtractionsHistory, setTenantData } = useContext(ElementContext)
    
  const { user } = useAuth();
  const domain = `@${user.email.split('@')[1]}`
  const { t } = useTranslation();
  const [remainingDays, setRemainingDays] = useState('')

  const userPlanFeatures = () => {
      let features;
      switch (userPlan) {
        case 'trial': features = globalEnv.PLANS.trial
            break;
        case 'personal': features = globalEnv.PLANS.personal
            break;
        case 'pro': features = globalEnv.PLANS.pro
            break;
        case 'business': features = globalEnv.PLANS.business
            break;
        case 'enterprise': features = globalEnv.PLANS.enterprise
            break;
        case 'plus01': features = globalEnv.PLANS.plus01
            break;
        case 'trial+': features = globalEnv.PLANS['trial+']
            break;
        default: break;
      }

      return features;
  }

  const existUserInDB = async() => {
    try {
        // Obtiene toda la información correspondiente al tenant
        const { contactos, historial, listas, tenant, globalSettings, userSettings, whatsappCount, smsCount, whatsappCountTrial, smsCountTrial, standardTemplates, aiTemplatesGenerated, executedJobs, schedules, userInfo, aiTemplatesGeneratedCount } = await getGlobalUserInformation(user.email);
        // , extractions, integrations, executedExtractions

        const globalEnvironment = await JSON.parse(globalSettings.configuracion)
        const userConfiguration = await JSON.parse(userSettings.configuracion)
        const selectedWAID = await userConfiguration.selectedWACredentials

        setTenantData(tenant)
        // setExtractionSettings(extractions)
        // setIntegrationsUserInformation(integrations)
        // setExecutedExtractionsHistory(executedExtractions)
        setSelectedWACredentials(selectedWAID)
        const tenantid = user.email.replace('@', '_')
        setIsDarkMode(globalEnvironment.REACT_APP_DARK_MODE)
        setGlobalEnv(globalEnvironment)
        setTenantId(tenantid)
        setUserPlan(tenant.plan)
        setSubscriptionPaid(JSON.parse(tenant.pago))
        setSubscriptionId(tenant.idsuscripcion)
        setDisclaimerAccepted(JSON.parse(tenant.avisolegalaceptado))
        setUserStripeData({
            customerid: await tenant.customerid,
            idsuscripcion: await tenant.idsuscripcion
        })
        setMessagesToShow(historial);
        setUserInformation(userInfo)

        const filterCommonLists = await listas.filter(lista => lista.tipodelista === 'common')
        setListNames(filterCommonLists)
        
        setAllTenantContacts(contactos)
        setDelayMsg(userConfiguration.delay)
        setOscillationMsg(JSON.parse(userConfiguration.oscilacionTiempo))
        setUserConfiguration(userConfiguration)
        setTemplatesToShow(standardTemplates)
        setAiTemplatesGenerated(aiTemplatesGenerated)
        setAiTemplatesGeneratedCount(aiTemplatesGeneratedCount)
        setScheduleToShow(schedules)
        setScheduleExecutedToShow(executedJobs)
        
        const filterFileLists = await listas.filter(lista => lista.tipodelista === 'file')
        const fileListData = await filterFileLists.map(fileList => {
            const fileCloud = JSON.parse(fileList.configuracion)
            fileCloud.idlist = fileList.idlista
            return fileCloud
        })

        if(fileListData){
            for(let fileInfo in fileListData){
                const fileUrl = await fileListData[fileInfo].fileUrl
                const fileInfoRequest = await fetchBackend('cloud-file-info', {fileUrl}, 'POST')
                const fileInfoResponse = await fileInfoRequest.json()
                const fileName = await fileInfoResponse.fileMetaData.properties.title
                fileListData[fileInfo].fileName = fileName
            }
            setCloudConnectionsList(fileListData)
        } else {
            console.log('No hay cloud files guardadas')
            setCloudConnectionsList([])
        }
        
        if(userConfiguration.whatsappAPIcredentials && userConfiguration.whatsappAPIcredentials.length > 0){
            const WACredentials = await userConfiguration.whatsappAPIcredentials;
            const selectWACredentials = await WACredentials.filter( wacred => wacred.phoneNumberId === selectedWAID )[0]
            setUserWAPICredentials(WACredentials)
            const whatsappApiTemplates = await getWhatsappApiTemplates(selectWACredentials)
            const allTemplates = whatsappApiTemplates.allTemplates
            const approvedTemplates = whatsappApiTemplates.approvedTemplates
            setWhatsappTemplates(allTemplates)
            setApprovedTemplates(approvedTemplates)

            const WAPIUserInfo = []
            for(let credentials of WACredentials){
                const whatsappApiPhoneNumber = await getPhoneNumberInformation(credentials)
                if(whatsappApiPhoneNumber.error){
                    console.log(whatsappApiPhoneNumber.error)
                } else {
                    WAPIUserInfo.push(whatsappApiPhoneNumber)
                }
            }
            setwAPIUserInformation(WAPIUserInfo)
        }
        
        const countryCodeList = countryCodes.customList('countryCode', '+{countryCallingCode}')
        const isoCode = countries.getAlpha2Code(userInfo.pais, 'es') ? countries.getAlpha2Code(userInfo.pais, 'es') : countries.getAlpha2Code(userInfo.pais, 'en')
        userInfo['isoCode'] =  isoCode
        userInfo['countryCode'] = await countryCodeList[isoCode]
        setUserInformation(await userInfo)

        if(tenant.plan === 'trial'){
            setTotalMsgs(whatsappCountTrial);
            setTotalSMS(smsCountTrial);
        } else {
            setTotalMsgs(whatsappCount);
            setTotalSMS(smsCount);
        }

        
        if(tenant.plan === 'trial'){
            // Convierto fecha leida en DB a un short-date admitido por Luxon
            const startString = DateTime.fromISO(tenant.fechaalta).toISODate();
            // Calculo su expiración de TRIAL basado en su fecha de alta
            const fechaHasta = DateTime.fromISO(startString).plus({days: 15}).toISODate();
            // Declaro la fecha de hoy y la convierto a short-date en formato Luxon
            const fechaHoy = DateTime.now().toString();
            const fechaDesde = DateTime.fromISO(fechaHoy).toISODate();
            // Obtengo los formatos que luego Luxon calculará
            const converStartDate = DateTime.fromISO(fechaDesde);
            const convertEndDate = DateTime.fromISO(fechaHasta);
            // Calcular la diferencia entre ambas fechas
            const diffInDays = convertEndDate.diff(converStartDate, 'days').toObject();

            setRemainingDays(diffInDays.days)
            
            const filteredHistorial = await historial.filter( data => data.estado === 'true' );
            const trialTotalMsgs = await filteredHistorial.length
            setTotalMsgs(trialTotalMsgs)
        }
    } catch (error) {
        console.log((t("theRequestedInformationNotFound")) + error)
    }
  }

  useEffect(() => {
      existUserInDB();
  }, [])


  // Expresiones de validación
  const planDescription = userPlanFeatures()
  const trialConditions = (userPlan === 'trial') && (remainingDays > 0 && totalMsgs < planDescription.maxMessages)
  const planConditions = (userPlan === 'personal' || userPlan === 'pro' || userPlan === 'business' || userPlan === 'enterprise' || userPlan === 'plus01' || userPlan === 'trial+') && (totalMsgs < planDescription.maxMessages) && subscriptionPaid


//   if(globalEnv && globalEnv.BANNED_DOMAINS.includes('@seidoranalytics.com')){
//     for(let i of globalEnv.BANNED_DOMAINS){
//         if(user.email.includes(i)){
//             return <NotificationText />
//         }
//     }
//   }

    if(globalEnv && globalEnv.BANNED_DOMAINS.includes(domain)){
        return <NotificationText />
    }

  // Mientras los datos no estén listos, mostrar un loader
  return (!userPlan || totalMsgs === '') ? (
        <CheckingLoader />
  ) : !disclaimerAccepted ? (
        <div>
            <p
                className='text-center text-91 border pointer py-2'
            >
                {t('disclaimerAccordion')}
            </p>
            <PreValidationDisclaimer />
        </div>
  ) : (
    <div>
        {
            !showAllPlans && (
                <>
                    <p
                        className='text-center text-91 border pointer py-2'
                        onClick={ () => setShowDisclaimer(!showDisclaimer) }
                    >
                        {t('disclaimerAccordion')}
                    </p>
                    <PreValidationDisclaimer />
                </>
            )
        }
        
        <div style={{ "height": "90vh" }} className='d-flex flex-column justify-content-center align-items-center'>

            <div>
                {
                    !showAllPlans && <div className='harbinger-logo-azul'></div>
                }
                
                {
                    trialConditions || planConditions ? (
                        <div>
                            <div className='d-flex flex-column align-items-center'>
                                <h3 className="text-center mb-4">{t('welcome')}</h3>
                                <span>
                                    {t('abstract')} <strong>{DateTime.now().toLocaleString()}</strong>
                                </span>
                                <span>{t('yourPlan')} <strong>{userPlan.toUpperCase()}</strong></span>
                            </div>

                            <div className="d-flex flex-column mt-5">
                                <h5>{t('whatsappCounter')}</h5>
                                <ul className='mb-4'>
                                    <li>
                                        <h6 className='mb-0'>
                                            {t('sent')}: {totalMsgs} {t('outOf')} {planDescription.maxMessages}
                                        </h6>
                                    </li>
                                    <li>
                                        <h6 className='mb-0'>
                                            {t('remaining')}: {planDescription.maxMessages - totalMsgs}
                                        </h6>
                                    </li>
                                </ul>
                            </div>
                            
                            {
                                globalEnv.SIDEBAR_MENU.SEND_SMS.includes(userPlan) && (
                                    <div className="d-flex flex-column">
                                        <h5>{t('smsCounter')}</h5>
                                        <ul className='mb-4'>
                                            <li>
                                                <h6 className='mb-0'>
                                                    {t('sent')}: {totalSMS} {t('outOf')} {planDescription.maxSMS}
                                                </h6>
                                            </li>
                                            <li>
                                                <h6 className='mb-0'>
                                                    {t('remaining')}: {planDescription.maxSMS - totalSMS}
                                                </h6>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            }

                            {
                                userPlan === 'trial' ? (
                                    <h5>{t('expires')}: {remainingDays} {t('days')}</h5>
                                ) : null
                            }

                            <button
                                className='btn btn-sm btn-mediumblue btn-check-width mt-4'
                                onClick={() => { setUserChecked(true) }}
                            >
                                {t('continue')}
                            </button>
                        </div>
                    ) : (
                        showAllPlans
                        ?   (<div className='mt-5 py-5'>
                                <UpdatePlanScreen />
                            </div>)
                        :   <UpdateUserPlan
                                userPlan={userPlan}
                                userEmail={user.email}
                                subscriptionPaid={subscriptionPaid}
                            />
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default CheckValidations